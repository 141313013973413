<template>
    <div>
        <b-container v-if="!getJobsPending">
            <div class="mb-3">
                <Spinner size="medium" line-fg-color="#e91e63" />
            </div>
        </b-container>
        <b-container v-else>
            <section v-if="getFilteredJobs.length != 0 && getJobsPending" class="events pt-0">
                <div class="sector pb-0 mb-0">
                    <h2 class="main-title">Latest Job Offers</h2>
                    <p class="main-subtitle">Explore the latest job offers</p>
                    <b-row>
                        <b-col v-for="job in orderBy(getFilteredJobs, 'id', -1)" :key="job.id" cols="12" lg="6">
                            <div class="jobs__element">
                                <div v-if="job.ribbon" class="custom-ribbon custom-ribbon--right">
                                    <span class="custom-ribbon__text custom-ribbon__text--green">Featured</span>
                                </div>
                                <b-row>
                                    <b-col cols="10" md="8" lg="6" xl="8">
                                        <p class="jobs__form">{{ job.type.text }}</p>
                                        <h2 class="jobs__position" v-line-clamp:22="1">{{ job.position }}</h2>
                                        <span class="jobs__info"><span class="agencies__star-tooltip" v-b-tooltip title="Company"><simple-line-icons icon="briefcase" size="small" color="#504e70" /></span>{{ job.company }}</span>
                                        <span class="jobs__info"><span class="agencies__star-tooltip" v-b-tooltip title="Location"><simple-line-icons icon="locationPin" size="small" color="#504e70" /></span>{{ job.city }}, {{ job.country.text }}</span>
                                    </b-col>
                                    <b-col cols="12" md="4" lg="6" xl="4" style="display: flex;">
                                        <button @click="redirectToLatest(job.id)" class="details-button details-button--bottom"><simple-line-icons icon="arrowRight" size="small" color="#504e70" />View details</button>
                                    </b-col>
                                </b-row>
                            </div>
                        </b-col>
                    </b-row>
                    <b-row class="justify-content-center mb-4">
                        <router-link to="/jobs" class="details-button details-button--red"><simple-line-icons icon="arrowRight" size="small" color="#504e70" />View more</router-link>
                    </b-row>
                </div>
            </section>
        </b-container>
    </div>
</template>

<script>
import SimpleLineIcons from 'vue-simple-line'
import { mapGetters } from 'vuex'
import Spinner from 'vue-simple-spinner'
import router from '../router/router'
import Vue2Filters from 'vue2-filters'
export default {
  components: {
    SimpleLineIcons,
    Spinner
  },
  mixins: [Vue2Filters.mixin],
  computed: {
    ...mapGetters([
      'getLatestJobs',
      'getJobsPending'
    ]),
    getFilteredJobs () {
      var jobs = Object.values(this.getLatestJobs).filter((job) => {
        return !job.id.includes(this.$route.params.id)
      })

      return jobs
    }
  },
  methods: {
    redirectToLatest (routeId) {
      router.push({ name: 'job', params: { id: routeId } })
      this.$store.dispatch('getJob', { id: routeId })
    }
  },
  beforeCreate () {
    this.$store.dispatch('getLatestJobs')
  }
}
</script>
